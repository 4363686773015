var lazyload = function(){
    if(!isMobile) {
        [].forEach.call(document.querySelectorAll('img[data-src]'), function(img) {
            img.setAttribute('src', img.getAttribute('data-src'));
            img.onload = function() {
                img.removeAttribute('data-src');
            };
        });
    } else {
        [].forEach.call(document.querySelectorAll('img[data-mob]'), function(img) {
            img.setAttribute('src', img.getAttribute('data-mob'));
            img.onload = function() {
                img.removeAttribute('data-mob');
                $(img).addClass('mobile-image');
            };
        });
    }
};
