var devMode = false;
var shortScreen = false;
var tallScreen = false;

$(document).ready(function() {
	window.scrollTo(0,10);
	calculator();
	if(!devMode) {
		//window.scrollTo(0,0);
		setTimeout(function(){
			$('body').removeClass('loading');
			$('#loading').css({
				'animation':'fadeOut 0.5s',
				'animation-fill-mode':'forwards'
			});
		}, 500);

	} else {
		$('#loading').remove();
		$('body').removeClass('loading');
	}
    Handlers();
	lazyload();
	formHandler();
});

if ( !devMode ) {
	window.onbeforeunload = function(){
		window.scrollTo(0,0);
	}
}

$(window).load(function(){
	console.log('WINLOAD mobile content height:',$('#mobile-content').innerHeight());
	scene4();
});
