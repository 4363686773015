Handlers = function(){

    // // Scroll Indicators Click Handlers
    // $('a.point , a[href^="#"]').on({
    //     click: function(e){
    //         e.preventDefault();
    //
    //         var wH   = $(window).innerHeight(),
    //             tar  = this.hash,
    //             $tar = $(tar),
    //             tarH = $(tar).height(),
    //             off  = (wH/2) - (tarH/2);
    //
    //         console.log(tarH);
    //
    //         $('html, body').stop().animate({
    //                 'scrollTop': $tar.offset().top - off
    //             },900 , 'swing', function(){
    //                 window.location.hash = tar;
    //             }
    //         );
    //     }
    // });
    $('.contentToggle').on({
        click: function() {
            $('.content-scroll-track').each(function(){
                // if ( $('#content-block').hasClass('shortScreen') ) {
                //     if( !$(this).hasClass('movedRight') ){
                //         TweenMax.to('#logo',0.2,{
                //             opacity:0,
                //             ease: Linear.easeNone
                //         });
                //     } else {
                //         TweenMax.to('#logo',0.2,{
                //             opacity:1,
                //             ease: Linear.easeNone
                //         });
                //     }
                // }
                $(this).toggleClass('movedRight');
            });
        }
    });
}
