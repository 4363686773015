var formHandler = function(){
    var optingIn = false;

    $('label').on({
        click: function(e){
            var parent = $(this).parents('form');
            var fauxCheckbutton = parent.find('.fauxCheckbutton');
            $(fauxCheckbutton).toggleClass('choice');
            console.log( $(parent).find('input[name=optIn]:checked') );
            optingIn = !optingIn;
        }
    });

    $('.join-button').on({
        click: function(e){
            e.preventDefault();
            console.log('submission requested');
            var self = $(this);
            var parent = $(this).parent('form');
            console.log(parent);
            var name = parent.find('input[name=name]'),
                email = parent.find('input[name=email]'),
                organisation = parent.find('input[name=organisation]')
                optIn = parent.find('input[name=optIn]');

            console.log($(name).val());

            // clear away any previous errors
            var validationErrors = 0;
            parent.find('#error-container , #error-container-mobile').removeClass('errors').html('');

            // check a name has been entered
            if( !$(name).val() ){
                throwValidationError(2);
                validationErrors++;
            }

            // check if email address is valid
            if( $(email).val().indexOf('@') < 1 || $(email).val().indexOf('.') < 1 ) {
                throwValidationError(1);
                validationErrors++;
            }

            if( validationErrors > 0 ) {
                $('#error-container , #error-container-mobile').addClass('errors');
                return;
            }

            var data = {};
            data.name           = $(name).val();
            data.email          = $(email).val();
            data.organisation   = $(organisation).val();
            if( optingIn ){
                data.optIn = true;
            } else {
                data.optIn = false;
            }
            console.log(data);
            $.ajax({
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                async: true,
                cache: false,
                headers: { "cache-control": "no-cache" },
                url:('/'),
                success: function(result){
                    console.log("result:",result);
                    submissionSuccess(result);
                },
                failure: function(result){
                    alert('AJAX FAILURE');
                }
            })
        }
    });
};

// $('input').on({
//     focus: function(e){
//         console.log('input focus fired');
//         if ( $(document).scrollTop() != scrollPosition ) {
//             $(document).scrollTop(scrollPosition);
//             console.log('readjusted scroll position');
//         }
//     }
// })

var submissionSuccess = function(result){
    $('#signUpForm , #signUpForm-mobile').addClass('sent');
    $('#thankYou , #thankYou-mobile').html('<h3>'+result.success+'</h3>').addClass('sent');
};

var throwValidationError = function(caller) {
    // 1 = invalid email address
    // 2 = no name entered
    var message = '';
    switch(caller) {
        case 1 :
            message = 'Please enter a valid email address. ';
            break;
        case 2 :
            message = 'Please enter your name. ';
            break;
    };
    $('#error-container , #error-container-mobile').append(message);
};
