var controller = new ScrollMagic.Controller();
var wH = $(window).innerHeight();
var wW = $(window).innerWidth();
var dH = $(document).height();
var maxMove = 150;
var tweenDuration = 1;
var timeline = new TimelineMax();
var isAndroid = false; var isIpad = false;

var blue  = '#5782e8',
    green = '#17a454',
    orange = '#ff9d02',
    red    = '#dc0101';

var isMobile, isDesktop;
console.log(isMobile);
var uAgent = navigator.userAgent;
if (window.matchMedia("(max-width: 768px)").matches) {
    /* The viewport is less than, or equal to, 700 pixels wide */
    isMobile = true;
	console.log('Is is a mobile apparently.');
} else {
    /* The viewport is greater than 700 pixels wide */
	isMobile = false;
}

if( uAgent.indexOf('Android') > 0 ) {
    isAndroid = true;
}

if(uAgent.indexOf('iPad') > 0 ) {
    isMobile = false;
    isIpad = true;
    $('.desktopOnly').removeClass('desktopOnly');
}

var flowOnly = false;
if(flowOnly){
    $('body').addClass('flowOnly');
}

// NAVIGATION Scene
var navigationTween = TweenMax.to('#scrollPoint',1,{
    top:'100%',
    rotation:360,
    ease: Linear.easeNone
});
var navigation = new ScrollMagic.Scene({
    triggerElement: '#trigger-scene-1',
    triggerHook:0,
    duration: ( dH - wH )
})
.setTween([navigationTween])
.addTo(controller);
// ----------------------------------------//

var scene1Duration = $('#scroll-section-1').height();

var bk1 = TweenMax.to('.background',1,{
    backgroundColor: red,
    ease: Linear.easeNone
});

var introductionCopyFade = TweenMax.to('#introduction',1,{
    opacity:0.2,
    top: '100vh',
    //y:"+=100vh",
    ease: Linear.easeNone
});


var fadeChevron = TweenMax.to('.chevron',0.2,{
    opacity:0,
    ease: Linear.easeNone
})

// var logoGroups = TweenMax.staggerTo('#logo g g',1,{
//     top:'-=300',
//     ease: Linear.easeNone
// },0.5);
if(!isMobile) {
    var moveDownLogo = TweenMax.to('.logo-container',1,{
        top:0,
        ease: Linear.easeNone
    });
} else {
    var dist = '-16vh';
    if ( isAndroid ) dist = '-14vh';
    var moveDownLogo = TweenMax.to('.logo-container',1,{
        top: dist,
        ease: Linear.easeNone
    });
}

var spinningBlocks = new TimelineMax();
$('#logo g g').children().each(function(index){
    var rand = Math.round( (Math.random() * 360) + 1 );
    this.tl = TweenMax.from(this,1,{
        rotation:rand,
        ease: Linear.easeNone
    });
    spinningBlocks.add(this.tl,0);
});

var moveGroups = new TimelineMax();
$('#logo g g').each(function(index){
    var rand = Math.round( (Math.random() * 600) + 1 );
    this.tl = TweenMax.from(this,1,{
        y:'-='+rand,
        opacity:0,
        ease: Linear.easeNone
    });
    moveGroups.add(this.tl,0);
});

var introductionScene = new ScrollMagic.Scene({
    triggerElement: '#trigger-scene-1',
    triggerHook:0,
    duration:scene1Duration
})
.setTween([introductionCopyFade,moveDownLogo,spinningBlocks,moveGroups,bk1,fadeChevron])
.addTo(controller);

//.setPin('#introduction')
//.addTo(controller);
// var hasBeenTrigged = false;
// $(window).scroll(function() {
//     if ($(this).scrollTop() >= 1 && !hasBeenTrigged) { // if scroll is greater/equal then 100 and hasBeenTrigged is set to false.
//         // SCENE 1 --------------------------------//
//
//         hasBeenTrigged = true;
//     }
// });

// SCENE 2 --------------------------------//

var scene2Duration = $('#scroll-section-2').height();
$('.move').each(function(index){
    this.tween = TweenMax.from(this,tweenDuration,{
        x: Math.floor(Math.random() * ((maxMove*2)+1) ) - (maxMove/2),
        y: Math.floor(Math.random() * ((maxMove*2)+1) ) - (maxMove/2),
        ease: Linear.easeNone
    });
    timeline.add(this.tween,0);
    console.log('tween created');
})

// var bk2 = TweenMax.to('.background',1,{
//     backgroundColor:'#9ed860',
//     ease: Linear.easeNone
// });

var straplineFade = TweenMax.staggerFrom('#strapline-group path',1,{
    opacity:0,
    ease: Linear.easeNone
},0.1);
timeline.add(straplineFade,1);

var lineTween = TweenMax.from('#line',1,{
    x:"-=1000",
    y:'-=1000',
    ease: Linear.easeNone
});
var logoAssemblyScene = new ScrollMagic.Scene({
    triggerElement:'#trigger-scene-2',// '#trigger2',
    triggerHook:1,
    duration: scene2Duration/2
})
.setTween([timeline,lineTween])//.setPin('#Layer_1');
.setClassToggle('.logo-container','animating')
.on('end', function(e){
    console.log('group left: ', $('.logo-elements').position())
    var logoLeft = $('.logo-elements').position().left;
    // $('.strapline').css('left',(logoLeft+20));
    // $('.subheading-container').css('left',(logoLeft+20));
})
.addTo(controller);

//--------------------------------//


// SCENE 3 --------------------------------//
$('#scroll-section-3').css('height','200vh');
var scene3Duration = $('#scroll-section-3').height();

var bk3 = TweenMax.to('.background',1,{
    backgroundColor:orange,
    ease: Linear.easeNone
});
//

var backgroundFades = new TimelineMax();
backgroundFades.to('.einstein',1,{
    opacity:1,
    ease: Linear.easeNone
},0)
.to('.blocks',0.4,{
    opacity:1,
    ease: Linear.easeNone
},1)
.to('.subheading',1,{
    opacity:1,
    ease: Linear.easeNone,
    //repeat:1,
    //yoyo:true
},0)
// .to('.subheading',0.3,{
//     y:'-=30',
//     opacity:0,
//     ease: Linear.easeNone
// },1)
.to('.subheading2',1,{
    opacity:1,
    ease: Linear.easeNone,
    // repeat:1,
    // yoyo:true
},1);



//var subheadingsFade = new TimelineMax();

//subheadingsFade


// var subheadingFade = TweenMax.to('.subheading',1,{
//     opacity:1,
//     ease: Linear.easeNone,
//     repeat:1,
//     yoyo:true
// })

var backgroundFadesScene = new ScrollMagic.Scene({
    triggerElement:'#trigger-scene-3',// '#trigger2',
    duration: scene3Duration
})
.setTween([backgroundFades,bk3])
.addTo(controller);

//--------------------------------//

// SCENE 4 --------------------------------//
var scene4 = function(){
    var scene4Duration = $('#scroll-section-4').height();

    var bk4 = TweenMax.to('.background',1,{
        backgroundColor:green,
        ease: Linear.easeNone
    });

    var fadeBlockCopy = TweenMax.to('.subheading-container',0.2,{
        opacity:0,
        ease: Linear.easeNone
    });

    if( !isMobile && !shortScreen ) {
        var makeSmallerLogo = TweenMax.to('#logo',1,{
            scale: 0.55,//0.35,
            top:'15.6974%',
            left:'15.6974%',
            ease: Linear.easeNone
        });
    } else if ( isMobile || shortScreen ) {
        var makeSmallerLogo = TweenMax.to('#logo',1,{
            //scale: 0.55,//0.35,
            top:'0',
            opacity:0,
            //left:'25%',
            ease: Linear.easeNone
        });
    }



    var removeImages = TweenMax.to('.background img',0.5,{
        opacity:0,
        ease: Linear.easeNone
    });

    var showCopy = TweenMax.set('#mobile-content',{
        opacity:1,
    })

    if(!isMobile) {
        var revealContent = TweenMax.to('#content-block',2,{
            top:'0%',
            //bottom: '100%',
            opacity:1,
            ease: Linear.easeNone
        });
    } else {
        var contentTop = $('#mobile-content').innerHeight() - $(window).height();
        console.log('window height:',$(window).height());
        console.log('mobile content height:',$('#mobile-content').innerHeight());
        console.log('CT:' , contentTop);
        var revealContent = TweenMax.to('#content-block',2,{
            top:'-'+(contentTop)+'px',
            //bottom: '0',
            ease: Linear.easeNone
        });
    }


    var straplineTimeline = new TimelineMax();
    // var straplineFadeout = TweenMax.to('.strapline',1,{
    //     opacity:0,
    //     ease:Linear.easeNone
    // });
    //straplineTimeline.add(straplineFadeout,0.3);

    straplineTimeline.to('#strapline-group',0.3,{
        opacity:0,
        ease:Linear.easeNone
    })
    .to('#strapline-group',0.3,{
        //top:'9%',
        //left:'17%',
        attr: { transform:'translate(475,-100)'},
        ease:Linear.easeNone
    })
    // .to('#strapline-group',0.3,{
    //     opacity:1,
    //     ease:Linear.easeNone
    // })

    var moveToContent = new ScrollMagic.Scene({
        triggerElement:'#trigger-scene-4',// '#trigger2',
        duration: scene4Duration
    })
    .setTween([showCopy,makeSmallerLogo,removeImages,revealContent,straplineTimeline,bk4,fadeBlockCopy])
    .setClassToggle('.einstein','hide')
    .on('end',function(event){
        console.log(event.scrollDirection);
        if(event.scrollDirection === 'FORWARD') {
            TweenMax.to('#strapline-group',0.3,{
                opacity:1,
                ease:Linear.easeNone
            })

        } else {
            TweenMax.to('#strapline-group',0.3,{
                opacity:0,
                ease:Linear.easeNone
            })
        }
        // PUT A LOCK ON THE BODY SCROLL HERE.
        //$('body').addClass('locked');

    })
    .addTo(controller);
}

if( $('body').hasClass('join') ) controller.destroy();

















//moveToContent.addIndicators();
//--------------------------------//


// $('.content-scroll-track').on('scroll',function(){
//         console.log( $('.content-scroll-track').scrollTop );
// })

$('.content-scroll-track').on('scroll',function(){
    //    console.log( $('.content-scroll-track').scrollTop );
})

// if(!isMobile) {
//     window.onresize = function() {
//         // Do something.
//         location.reload();
//     }
// } else {
//     // window.onresize = function() {
//     //     // Do something.
//     //     //alert('this is a phone and resize has happened');
//     //     //backgroundFadesScene.progress(0);
//     //     backgroundFadesScene.addTo(controller)
//     // }
// }

var scrollPosition = 0;
$(document).scroll(function(){
    scrollPosition = $(this).scrollTop();
    //console.log('scroll position: ', scrollPosition);

})

$('input').on('focus', function(){
    if( isAndroid ) {
        $('html, body').animate({
            scrollTop: $(this).offset().top - 20 //#DIV_ID is an example. Use the id of your destination on the page
        }, 100);
    }
})

$(window).bind('orientationchange', function(e) {
    if (!isIpad) {
        switch ( window.orientation ) {
      case 0:
        $('.turnDeviceNotification').css('display', 'none');
        // The device is in portrait mode now
      break;

      case 180:
        $('.turnDeviceNotification').css('display', 'none');
        // The device is in portrait mode now
      break;

      case 90:
        // The device is in landscape now
        $('.turnDeviceNotification').css('display', 'block');
      break;

      case -90:
        // The device is in landscape now
        $('.turnDeviceNotification').css('display', 'block');
      break;
     }
    }
});
// $(window).on('scroll', function(e){
//     var scrollPercent = 100 * $(window).scrollTop() / ($(document).height() - $(window).height());
//     //console.log(scrollPercent);
//     $('#scrollPoint').css('top',scrollPercent+'%');
// });


// console.log(wH);
//
//
// var scaleTween = TweenMax.to('#fixedtest',1,{
//     //scale: 1.4,
//     //backgroundColor: 'rgb(255, 39, 46)',
//     rotation: 90,
//     x: 200,
//     y: 200,
//     ease: Linear.easeNone
// })
//
// var scaleTween2 = TweenMax.to('#fixedtest2',1.5,{
//     rotation:45,
//     x:-100,
//     y:200,
//     ease: Linear.easeNone
// });
//
// var nextTween = TweenMax.to('#fixedtest3',1,{
//     x:-150,
//     y:-100,
//     rotation:100,
//     ease: Linear.easeNone
// });
//
// var anotherTween = TweenMax.to('#fixedtest4',1,{
//     x:600,
//     rotation:60,
//     ease: Linear.easeNone
// })
//
// console.log($('#testtext').children);
//
// var textfadeTween = TweenMax.staggerTo($('#testtext').children(),1,{
//     opacity:1
// },0.25);
//
// var example = new ScrollMagic.Scene({
//     triggerElement: '#fixedtest',
//     duration: wH/2,
//     offset: 220,
// }).setTween(scaleTween)
// .setPin('#fixedtest', { pushFollowers:false})
//
//
// //'#fixedtest2'
// var example2 = new ScrollMagic.Scene({
//     triggerElement: '#fixedtest',
//     duration: wH/2,
//     offset: 220,
// }).setTween(scaleTween2)
// .setPin('#fixedtest2')
//
//
//
//
// var slide2scene = new ScrollMagic.Scene({
//     triggerElement: '#section-2',
//     duration: wH/2,
// }).setTween([nextTween,anotherTween])
//
// var textfade = new ScrollMagic.Scene({
//     triggerElement: '#section-2'
// }).setTween(textfadeTween)
//
//
//
//
//
//
// if(devMode){
//     $('body').addClass('devMode');
//     slide2scene.addIndicators({
//         name: 'triggerDown', // custom name for your scene
//         indent: 520, // indent from the browser edge
//         colorStart: 'yellow', // custom color - colorEnd
//         colorTrigger: 'yellow'
//     })
// } else {
//     console.log = function(){}
// }
//
//
// controller.addScene([
//     example,
//     example2,
//     slide2scene,
//     textfade
// ])
// // .addTo(controller);
//
//
// $('.hasPoint').each(function(){
//     var tar = $(this).data('point'),
//         dH  = $(document).height(),
//         off = $(this).offset().top,
//         //p   = Math.round((off / dH ) * 100);
//         p   = (off / dH ) * 100;
//     console.log(p);
//     $('.point[data-point="'+tar+'"]').css('top',p+'%');
// });
//
// $(window).on('scroll', function(e){
//     var scrollPercent = 100 * $(window).scrollTop() / ($(document).height() - $(window).height());
//     //console.log(scrollPercent);
//     $('#scrollPoint').css('top',scrollPercent+'%');
// });
// //
