var calculator = function() {
    console.log('firing calculator');
    var winH = $(window).innerHeight(),
        introH = $('.introContent').innerHeight();

    if ( winH < 771 ) {
        shortScreen = true;
        $('.introContent').addClass('shortScreen');
        $('.subheading-container').addClass('shortScreen');
        $('#content-block').addClass('shortScreen');
    }
    if ( winH >809 ) {
        tallScreen = true;
        $('.right-half').addClass('tallScreen');
    }
};
